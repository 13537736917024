<template>
    <div class="_wrap">



        <div class="_content">
            <div class="_auto">
                <div class="_bread">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item :to="{ path: baseDetail.path }">{{ baseDetail.breadName
                            }}</el-breadcrumb-item>
                        <el-breadcrumb-item><span class="_myt">{{ detail.title }}</span></el-breadcrumb-item>
                    </el-breadcrumb>
                </div>


                <div class="_nr">
                    <div class="_title">{{ detail.title }}</div>

                    <div class="_subtit">
                        <div class="_left">
                            <span class="_time">{{ detail.release_date }}</span>
                            <span class="_ydul">阅读量：{{ detail.hits }}</span>
                        </div>
                        <div class="_right">
                            <span>分享：</span>
                            <!-- <img class="_wb" src="@/assets/images/wb.png" alt="" @click="goWb" style="cursor: pointer;"> -->



                            <el-popover placement="top" width="180" trigger="hover" style="cursor: pointer;">
                                <div class="_code">
                                    <!-- <img :src="$IMG_URL" alt="八路军驻洛办事处纪念馆-官方网站"> -->
                                    <div id="qrcode" ref="qrcode"></div>
                                </div>
                                <img slot="reference" src="@/assets/images/wx.png" alt="八路军驻洛办事处纪念馆-官方网站">
                            </el-popover>







                        </div>

                    </div>

                    <div class="_cont">
                        <iframe v-if="baseDetail.breadName == '书刊精选'"
                            :src="'/pdf/web/viewer.html?file=' + $IMG_URL + detail.pdf" frameborder="0"
                            id="myIframe"></iframe>

                        <div v-else class="_html" ref="myHtml" v-html="detail.content"></div>
                        <!-- <div v-else class="_html" ref="myHtml">

                            <p>
                                <span>
                                    <img src="https://www.zglyzjng.com//ueditor/php/upload/image/20240112/1705023643848268.jpg"
                                        alt="">

                                </span>
                            </p>
                        </div> -->

                    </div>

                </div>
            </div>
        </div>

        <fixIcon></fixIcon>

        <coment ref="comentRef"></coment>
        <mainFooter ref="mainFooterRef"></mainFooter>
    </div>
</template>

<script>

import { getDetail, pdfFileStreams } from "@/api/getData"
import { listMenu } from "../../components/navMenu/list"

import QRCode from 'qrcodejs2'
export default {
    data() {
        return {
            timer: null,

            pdfLink: "",
            listMenu: listMenu,
            id: "",
            iframeSrc: "",
            baseDetail: {
                breadName: "",
                path: "",
            },
            breadName1: "详情",
            detail: {},

        }
    },

    created() {
        // let title = this.$route.query.title
        // this.breadName = title
    },

    async mounted() {
        await this.getDetail()

        if (this.baseDetail.breadName != '书刊精选') {
            this.$scallHtmlImg()
        }

        this.creatQrCode()

        // this.iframeSrc = ``../../web/viewer.html?file=';

    },
    methods: {
        async getDetail() {
            this.baseDetail.breadName = this.$route.query.tit

            for (let i in this.listMenu) {
                if (this.baseDetail.breadName == this.listMenu[i].title) {
                    this.baseDetail.path = this.listMenu[i].id + '?tit=' + this.baseDetail.breadName
                }

                for (let v in this.listMenu[i].child) {
                    if (this.baseDetail.breadName == this.listMenu[i].child[v].subTitle) {
                        if (this.listMenu[i].child[v].id) {
                            this.baseDetail.path = this.listMenu[i].child[v].id + '?tit=' + this.baseDetail.breadName
                        } else {
                            this.baseDetail.path = this.listMenu[i].id + '?tit=' + this.baseDetail.breadName
                        }
                    }
                }

            }

            const params = {
                id: this.$route.query.id
            }
            await getDetail(params).then(res => {
                console.log(res)
                this.detail = res.data

            })
        },


        shareToWeibo(url) {
            const weiboShareUrl = `http://service.weibo.com/share/share.php?url=${url}`;
            window.open(weiboShareUrl, '_blank',);
        },

        goWb() {
            this.shareToWeibo(location.href);

        },
        creatQrCode() {
            var qrcode = new QRCode(this.$refs.qrcode, {
                text: location.href, // 需要转换为二维码的内容
                width: 150, // 生成二维码得宽度
                height: 150, // 生成二维码得高度
                colorDark: '#000', // 二维码实点的颜色
                colorLight: '#fff', // 二维码背景色
                correctLevel: QRCode.CorrectLevel.H // （1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
            })
        },
    }
}
</script>

<style lang="scss" scoped>
@import "@/common/mixin.scss";

#myIframe
{
    width: 100%;
    // height: 100%;
    height: 900px;
}

._wrap
{
    width: 100%;
    overflow: hidden;
    background-color: #F8F5EE;
}


::v-deep ._html
{
    p
    {
        background: transparent !important;

    }

    span
    {
        background: transparent !important;
    }
}
::v-deep ._code
{
    width: 150px;
    height: 150px;
    margin: 0 auto;
    // padding: 12px 0;
    // margin-left: -2px;
    img{
        width: 100%;
        height: 100%;
    }

}

._content
{
    width: 100%;
    margin-bottom: 100px;
    margin-top: 200px;

    ._auto
    {
        width: 85%;
        margin: 0 auto;

        ._bread
        {
            margin-top: 24px;
        }

        ._nr
        {
            margin-top: 66px;

            ._title
            {
                font-size: 32px;
                font-weight: bold;
                text-align: center;
                margin-bottom: 20px;
            }

            ._subtit
            {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 20px 0;
                border-top: 1px solid #D9D9D9;
                border-bottom: 1px solid #D9D9D9;

                ._left
                {
                    color: #666;
                    display: flex;
                    align-items: center;

                    ._time
                    {
                        margin-right: 80px;
                        position: relative;
                        margin-left: 20px;
                    }

                    ._time::before
                    {
                        content: "";
                        display: inline-block;
                        width: 4px;
                        height: 18px;
                        background: #8A2415;
                        position: absolute;
                        top: 0;
                        left: -20px;


                    }
                }

                ._right
                {
                    display: flex;
                    align-items: center;

                    ._wb
                    {
                        margin-right: 16px;
                        margin-left: 10px;
                    }
                }
            }

            ._cont
            {
                margin-top: 40px;
                min-height: 900px;
                padding: 20px 40px;
                // background-color: red;
            }
        }
    }
}

::v-deep .el-breadcrumb__inner
{
    color: #969696 !important;
    font-weight: normal !important;
}



._content
{
    @include respondTo('phone')
    {
        margin-top: 80px;

        ._auto
        {
            width: 95% !important;
            padding: 0;

            ._bread
            {
                margin-top: 0;
                height: 20px;

                .el-breadcrumb
                {
                    height: 100%;
                    line-height: 20px;
                }
            }

            ._myt
            {
                display: inline-block;
                overflow: hidden;
                width: 120px;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            ._nr
            {
                margin-top: 30px;

                ._title
                {
                    font-size: 24px;
                    margin-bottom: 10px;
                }

                ._subtit
                {
                    padding: 10px;

                    ._right
                    {
                        display: none;
                    }
                }

                ._cont
                {
                    padding: 0;
                    margin-top: 20px;
                }
            }

        }
    }
}
</style>